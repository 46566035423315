import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import TextField from '@mui/material/TextField';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import { Link as RouterLink } from 'react-router-dom';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { resetPasswordWhithEmail } from "../../services/auth/AuthServices";
import { debugLog } from "../../functions";
import { Alert } from '@mui/material';

let timer;
function PasswordReset() {
    const [email, setEmail] = useState('');
    const [showAlert, setShowAlert] = useState(false);
    const [loading, setLoding] = useState(false);

    const handleChange = (event) => {
        setEmail(event.target.value);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        setLoding(true);
        await resetPasswordWhithEmail(email);
        debugLog(`Enviando enlace de restablecimiento a: ${email}`);
        setEmail('');
        setShowAlert(true);
        setLoding(false);
    };

    useEffect(() => {
        if (timer) clearTimeout(timer);
        if (showAlert) {
            timer = setTimeout(() => {
                setShowAlert(false);
            }, 5000);
        }

        return () => clearTimeout(timer);
    }, [showAlert]);

    return (
        <Container component="main" maxWidth="xs">
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    mt: 8
                }}
            >
                <Box sx={{ mb: 3 }}>
                    <LockOutlinedIcon style={{ fontSize: 40, color: 'blue' }} />
                </Box>

                <Typography component="h1" variant="h5">
                    Restablecer contraseña
                </Typography>

                <Box component="form" onSubmit={handleSubmit} mt={3} width="100%">
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="email"
                        label="Correo Electrónico"
                        name="email"
                        autoComplete="email"
                        autoFocus
                        value={email}
                        onChange={handleChange}
                    />

                    <Button
                        disabled={loading || !email || !/\S+@\S+\.\S+/.test(email)}
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        sx={{ mt: 3, mb: 2 }}
                    >
                        {loading ? 'Enviando...' : 'Enviar enlace de restablecimiento'}
                    </Button>

                    {showAlert
                        && <Alert severity="success">
                            Se envió un enlace de restablecimiento de contraseña a tu
                            correo electrónico.
                        </Alert>
                    }

                    <Box mt={2} textAlign="center">
                        <Link variant="body2" component={RouterLink} to="/login">
                            ¿Ya tienes una cuenta? Inicia sesión
                        </Link>
                    </Box>
                </Box>
            </Box>
        </Container>
    );
}

export default PasswordReset;
