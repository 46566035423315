export const PERMISSIONS = {
  FILED: {
    LIST: 'filed.list', 
    CREATE: 'filed.create',
    READ: 'filed.read',
    UPDATE: 'filed.update',
    DELETE: 'filed.delete',
    CANCEL: 'filed.cancel'
  },
  REQUESTSFILED: {
    LIST: 'requestsFiled.list',
    CREATE: 'requestsFiled.create',
    READ: 'requestsFiled.read',
    UPDATE: 'requestsFiled.update',
    DELETE: 'requestsFiled.delete',
    RESEND_EMAIL: 'requestsFiled.resend_email',
  },
  TASK: {
    LIST: 'task.list'
  },
  PROCESS: {
    LIST: 'process.list',
    CREATE: 'process.create',
    READ: 'process.read',
    UPDATE: 'process.update',
    DELETE: 'process.delete',
    INSTANCE: 'process.instance',
    READ_FLOW: 'process.read_flow',
    CANCEL_FLOW: 'process.cancel_flow',
    SAVE_AS_ANY_EXPEDIENT: 'process.save_as_any_expedient',
    VIEW_INPROGRESS: 'process.view_inprogress',
    EDITOR_OPEN_FILE: 'process.editor_open_file'
  },
  EXPEDIENT: {
    VIEW: 'expedient.view',
    OWN: 'expedient.own',
    CREATE_TYPE: 'expedient.create_type',
    UPDATE_TYPE: 'expedient.update_type',
    DELETE_TYPE: 'expedient.delete_type',
    LIST: 'expedient.list',
    CREATE: 'expedient.create',
    READ: 'expedient.read',
    UPDATE: 'expedient.update',
    DELETE: 'expedient.delete',
    CREATE_DOCUMENT: 'expedient.create_document'
  },  
  CONTRACT: {
    LIST: 'contract.list',
    CREATE: 'contract.create',
    READ: 'contract.read',
    UPDATE: 'contract.update',
    DELETE: 'contract.delete'
  },
  SUPERVISOR_CONTRACT: {
    LIST: 'supervisor_contract.list',
    UPDATE: 'supervisor_contract.update',
    APPROVE: 'supervisor_contract.approve'
  },
  CONTRACTOR: {
    LIST: 'contractor.list',
    UPDATE: 'contractor.update'
  },
  REPORT: {
    LIST: 'report.list'
  },
  TEMPLATE: {
    LIST: 'template.list',
    CREATE: 'template.create',
    READ: 'template.read',
    UPDATE: 'template.update',
    DELETE: 'template.delete'
  },
  LAB: {
    LIST: 'lab.list'
  },
  CONFIG: {
    LIST: 'config.list'
  }
};

export const ROLES = {
  ADMIN: Object.keys(PERMISSIONS).map((k) => Object.values(PERMISSIONS[k])).flat(),
  ARCHIVER: [
    ...Object.values(PERMISSIONS.FILED).filter((p) => p !== PERMISSIONS.FILED.CANCEL),
    ...Object.values(PERMISSIONS.REQUESTSFILED),
    ...Object.values(PERMISSIONS.TASK),
    ...Object.values(PERMISSIONS.PROCESS).filter((p) => ![
      PERMISSIONS.PROCESS.CANCEL_FLOW,
      PERMISSIONS.PROCESS.VIEW_INPROGRESS,
      PERMISSIONS.PROCESS.EDITOR_OPEN_FILE
    ].includes(p)),
    ...Object.values(PERMISSIONS.EXPEDIENT),
    ...Object.values(PERMISSIONS.REPORT)
  ],
  USER: [
    ...Object.values(PERMISSIONS.REQUESTSFILED).filter((p) => p !== PERMISSIONS.REQUESTSFILED.RESEND_EMAIL),
    ...Object.values(PERMISSIONS.TASK),
    ...Object.values(PERMISSIONS.PROCESS).filter((p) => ![
      PERMISSIONS.PROCESS.CANCEL_FLOW,
      PERMISSIONS.PROCESS.VIEW_INPROGRESS,
      PERMISSIONS.PROCESS.SAVE_AS_ANY_EXPEDIENT,
      PERMISSIONS.PROCESS.EDITOR_OPEN_FILE
    ].includes(p)),
    PERMISSIONS.EXPEDIENT.OWN
  ],
  ADMIN_CONTRACT: [
    ...Object.values(PERMISSIONS.CONTRACT),
  ],
  SUPERVISOR_CONTRACT: [
    ...Object.values(PERMISSIONS.SUPERVISOR_CONTRACT),
    PERMISSIONS.CONTRACT.READ
  ],
  CONTRACTOR: [
    ...Object.values(PERMISSIONS.CONTRACTOR),
    PERMISSIONS.CONTRACT.READ
  ]
};

export const ARL = {
  L1: { value: "0.00522", text: "Riesgo I" },
  L2: { value: "0.01044", text: "Riesgo II" },
  L3: { value: "0.02436", text: "Riesgo III" },
  L4: { value: "0.04350", text: "Riesgo IV" }
};

export const SALARIO_MINIMO = 1300000;
export const UVT = 47065;

export const dependencies = [
  'Coordinación de Admisiones y Registro',
  'Coordinación de Adquisición y Bienes',
  'Coordinación de Aseguramiento de la Calidad',
  'Coordinación de Autoevaluación',
  'Coordinación de Ayudas Educativas',
  'Coordinación de Biblioteca',
  'Coordinación de Desarrollo Físico',
  'Coordinación de Egresados',
  'Coordinación de Gestión Documental',
  'Coordinación de Gestión Humana',
  'Coordinación de Laboratorios',
  'Coordinación de Presupuesto',
  'Coordinación de Servicios Generales',
  'Coordinación de TICS',
  'Consejo Académico',
  'Consejo Directivo',
  'Departamento de Ciencias Básicas y Áreas Comunes',
  'Dirección Administrativa y Financiera',
  'Dirección de Bienestar',
  'Dirección de Control Interno',
  'Dirección de Extensión',
  'Dirección de Internacionalización',
  'Dirección de Investigación',
  'Dirección de Planeación',
  'Dirección de Regionalización',
  'Facultad de Ciencias Administrativas y Económicas',
  'Facultad de Derecho y Ciencias Forenses',
  'Facultad de Educación y Ciencias Sociales',
  'Facultad de Ingeniería',
  'Oficina de Comunicaciones',
  'Rectoría',
  'Secretaría General',
  'Vicerrectoría Académica',
];
