import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams, Link as RouterLink } from "react-router-dom";
import {
  Container, Box, Grid, Typography, Button, CircularProgress, Link, IconButton
} from "@mui/material";
import { ArrowBack, Mail } from "@mui/icons-material";

import { getRequestFiled, resendEmail } from "../../services/backend/CorrespondenceService";
import FiledViewItem from "./FiledViewItem";
import { PERMISSIONS } from "../../constants";
import DialogConfirmation from "../generic/DialogConfirmation";

export default function FiledView() {
  const { hash } = useParams();
  const navigate = useNavigate();
  const user = useSelector((state) => state.user.value);
  const [request, setRequest] = useState();
  const [confirmInfo, setConfirmInfo] = useState({ open: false });

  useEffect(() => {
    (async () => {
      let responseJSON = await getRequestFiled(user?.token, hash);

      if (!responseJSON?.request) {
        navigate("/filedlist");
        return;
      }
      setRequest(responseJSON.request);
    })();
  }, [user, hash, navigate]);

  return (
    <>
      <DialogConfirmation
        confirmInfo={confirmInfo}
        handleCloseDialog={(result) => {
          resendEmail(user.token, request);
          setConfirmInfo({ open: false });
        }}
      />
      <Button
        variant="outlined"
        startIcon={<ArrowBack />}
        style={{ margin: 16 }}
        onClick={() => navigate(-1)}
      >
        Volver
      </Button>
      <Container maxWidth={false}>
        {request ? (
          <>
            <Grid container sx={{ m: 2 }}>
              <Grid item xs={2} sx={{ display: "flex", alignItems: "center" }}>
                <Typography>Solicitud:</Typography>
              </Grid>
              <Grid item xs={10} sx={{ display: "flex", alignItems: "center" }}>
                <FiledViewItem item={request} />
                {user?.permissions.includes(PERMISSIONS.REQUESTSFILED.RESEND_EMAIL)
                  && <IconButton
                    edge="end"
                    aria-label="delete"
                    onClick={() => setConfirmInfo({
                      open: true,
                      title: "¿Deseas reenviar los correos a los remitentes y destinatarios?"
                    })}
                  >
                    <Mail />
                  </IconButton>
                }
              </Grid>
            </Grid>
            <Grid container sx={{ m: 2 }}>
              <Grid item xs={2} sx={{ display: "flex" }}>
                <Typography>Anexos:</Typography>
              </Grid>
              <Grid item xs={10}>
                {request.annexes?.length === 0 && 'No hay anexos'}
                {request.annexes?.map((ann) => (
                  <Typography>
                    <a href={ann.url} target="_blank" rel="noreferrer">{ann.name}</a>
                  </Typography>
                ))}
              </Grid>
            </Grid>
            {request.expedient
              && <Grid container sx={{ m: 2 }}>
                <Grid item xs={2} sx={{ display: "flex" }}>
                  <Typography>Expediente:</Typography>
                </Grid>
                <Grid item xs={10}>
                <Link component={RouterLink} to={`/expedients/${request.expedient.expedientTypeId}/${request.expedient.expedientId}`}>
                  {`${request.expedient.description} (${request.expedient.expedientId})`}
                </Link>
                </Grid>
              </Grid>
            }
            <Grid container sx={{ m: 2 }}>
              <Grid item xs={2} sx={{ display: "flex" }}>
                <Typography>Respuesta:</Typography>
              </Grid>
              <Grid item xs={10}>
                {request.answers &&
                  request.answers.map(() => <FiledViewItem item={request} />)}
                {!request.answers?.lenght && (
                  <Typography>No hay respuestas para esta solicitud</Typography>
                )}
              </Grid>
            </Grid>
          </>
        ): (
          <Box sx={{ textAlign: 'center' }}>
            <CircularProgress />
          </Box>
        )}
      </Container>
    </>
  );
}
