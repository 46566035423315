import { firestore, server } from "../../config/ConfigFirebase";
import { doc, onSnapshot, query, collection, where } from "firebase/firestore";
import moment from "moment/moment";
import { debugLog } from "../../functions";

const handleCorrespondence = async (token, body) => {
  return await fetch(
    "https://" + server + ".cloudfunctions.net/handleCorrespondence",
    {
      method: "post",
      body: JSON.stringify(body),
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    }
  );
};

export const getCorrespondences = async (token) => {
  try {
    const body = { action: "GET_CORRESPONDENCES" };
    const response = await handleCorrespondence(token, body);
    let responseJSON = await response.json();
    return responseJSON;
  } catch (error) {
    return null;
  }
};

export const getCorrespondenceMigration = async (
  token,
  correspondence,
  createdAt
) => {
  try {
    const createdAtNew = createdAt ? new Date(createdAt) : new Date();
    const body = {
      action: "GET_DOCUMENT_CODE_MIGRATION",
      ...correspondence,
      createdAt: createdAtNew,
    };
    const response = await handleCorrespondence(token, body);
    let responseJSON = await response.json();
    responseJSON.createdAt = moment(createdAt);
    console.log("responseJSON: ", responseJSON);
    return responseJSON;
  } catch (error) {
    return null;
  }
};

export const generateReferenceFiled = async (token, correspondence) => {
  try {
    const createdAt = new Date();
    const body = {
      action: "GET_DOCUMENT_CODE",
      ...correspondence,
      createdAt,
    };
    const response = await handleCorrespondence(token, body);
    let responseJSON = await response.json();
    responseJSON.createdAt = moment(createdAt);
    return responseJSON;
  } catch (error) {
    return null;
  }
};

export const doneFiled = async (token, bodyRequest) => {
  try {
    const body = {
      action: "DONE_FILED",
      ...bodyRequest,
      doneAt: new Date(),
    };
    const response = await handleCorrespondence(token, body);
    let responseJSON = await response.json();
    return responseJSON;
  } catch (error) {
    debugLog(error);
    return null;
  }
};

export const updateFiled = async (token, bodyRequest) => {
  try {
    const body = { action: "SAVE_FILED", ...bodyRequest };
    const response = await handleCorrespondence(token, body);
    let responseJSON = await response.json();
    return responseJSON;
  } catch (error) {
    return null;
  }
};

export const getFiled = async (token, filedId) => {
  try {
    const body = { action: "GET_FILED", filedId };
    const response = await handleCorrespondence(token, body);
    let responseJSON = await response.json();
    if (responseJSON.request) responseJSON = responseJSON.request;
    return {
      ...responseJSON,
      createdAt: moment(responseJSON.createdAt),
      externalDate: responseJSON.externalDate
        ? new Date(responseJSON.externalDate)
        : undefined,
    };
  } catch (error) {
    return null;
  }
};

export const getFiledFilter = async (token, filter) => {
  try {
    const body = { action: "GET_ALL", ...filter };
    const response = await handleCorrespondence(token, body);
    let responseJSON = await response.json();
    responseJSON.forEach((element) => {
      element.createdAt = moment(element.createdAt);
      element.externalDate = element.externalDate
        ? new Date(element.externalDate)
        : undefined;
    });
    responseJSON.sort((a, b) => b.createdAt - a.createdAt);
    return responseJSON;
  } catch (error) {
    return null;
  }
};

export const getRequestFiled = async (token, referenceId) => {  
  try {
    const body = { action: "GET_REF", referenceId };
    const response = await handleCorrespondence(token, body);
    let responseJSON = await response.json();
    return {
      ...responseJSON,
      request: {
        ...responseJSON.request,
        createdAt: moment(responseJSON.request.createdAt),
        externalDate: responseJSON.request.externalDate
          ? new Date(responseJSON.request.externalDate)
          : undefined,
      },
    };
  } catch (error) {
    return null;
  }
};

export const getRequestsFiledFilter = async (user, filter) => {
  try {
    const body = {
      action: "GET_ALL_REF",
      userId: user.userId,
      ...filter,
    };
    const response = await handleCorrespondence(user.token, body);
    let responseJSON = await response.json();
    const fileds = [];
    responseJSON.forEach((element) => {
      const item = { ...element, ...element.request };
      item.createdAt = moment(element.createdAt);
      item.externalDate = element.externalDate
        ? new Date(element.externalDate)
        : undefined;
      fileds.push(item);
    });
    fileds.sort((a, b) => b.createdAt - a.createdAt);
    return fileds;
  } catch (error) {
    return null;
  }
};

export const getFiledOnSnapshot = (
  collectionName,
  documentId,
  handleDocument
) => {
  return onSnapshot(doc(firestore, collectionName, documentId), (doc) => {
    //debugLog("Current data: ", doc.data());
    const filed = {
      documentId: doc.id,
      ...doc.data(),
      createdAt: doc.data().createdAt?.toDate(),
      externalDate: doc.data().externalDate?.toDate(),
    };
    handleDocument(filed);
  });
};

export const getFiledListOnSnapshot = (callback) => {
  const startDate = new Date(new Date().setHours(0, 0, 0));
  const endDate = new Date(new Date().setHours(23, 59, 59));

  const queryFileds = query(
    collection(firestore, "filed"),
    where("createdAt", ">=", startDate),
    where("createdAt", "<=", endDate)
  );

  return onSnapshot(queryFileds, (snapshot) => {
    const fileds = [];
    snapshot.forEach((doc) => {
      const filed = {
        documentId: doc.id,
        ...doc.data(),
      };
      filed.createdAt = moment(filed.createdAt.seconds * 1000);
      if (filed.externalDate) filed.externalDate = new Date(filed.externalDate);
      fileds.push(filed);
    });
    fileds.sort((a, b) => b.createdAt - a.createdAt);
    callback(fileds);
  });
};

export const getRequestsFiledListOnSnapshot = (user, callback) => {
  const startDate = new Date(new Date().setHours(0, 0, 0));
  const endDate = new Date(new Date().setHours(23, 59, 59));

  const queryFileds = query(
    collection(firestore, "requestsFiled"),
    where("createdAt", ">=", startDate),
    where("createdAt", "<=", endDate),
    where("participants", "array-contains", user.userId)
  );
  return onSnapshot(queryFileds, (snapshot) => {
    const fileds = [];
    snapshot.forEach((doc) => {
      const data = doc.data();
      const filed = {
        documentId: doc.id,
        ...data,
        ...data.request,
      };
      filed.createdAt = moment(filed.createdAt.seconds * 1000);
      if (filed.externalDate) filed.externalDate = new Date(filed.externalDate);
      fileds.push(filed);
    });
    fileds.sort((a, b) => b.createdAt - a.createdAt);
    callback(fileds);
  });
};

export const resendEmail = async (token, bodyRequest) => {
  try {
    const body = { action: "RESEND_EMAIL", ...bodyRequest };
    const response = await handleCorrespondence(token, body);
    let responseJSON = await response.json();
    return responseJSON;
  } catch (error) {
    debugLog(error);
    return null;
  }
};

export const annexesList = [
  { id: 1, name: "Acta Análisis De Riesgos Previsibles" },
  { id: 2, name: "Acta De Audiencia" },
  { id: 3, name: "Acta De Audiencia De Sorteo" },
  { id: 4, name: "Acta De Audiencia I" },
  { id: 5, name: "Acta De Audiencia II" },
  { id: 6, name: "Acta De Cierre" },
  { id: 7, name: "Acta De Inicio Del Contrato" },
  { id: 8, name: "Acta De Interventoría Del Contrato " },
  { id: 9, name: "Acta De Manifestación De Interés" },
  { id: 10, name: "Acta De Pago" },
  { id: 11, name: "Acta De Recibo A Satisfacción" },
  { id: 12, name: "Acta De Subsanación" },
  {
    id: 13,
    name: "Acta De Terminación Y Liquidación Anticipada De Mutuo Acuerdo Del Contrato ",
  },
  { id: 14, name: "Adenda" },
  { id: 15, name: "Adición Del Contrato" },
  { id: 16, name: "Análisis Del Sector" },
  { id: 17, name: "Anexos " },
  { id: 18, name: "Antecedentes Disciplinarios De La Tarjeta Profesional" },
  { id: 19, name: "Antecedentes Y Requerimientos Judiciales" },
  { id: 20, name: "Apertura Cuenta Nomina Davivienda" },
  { id: 21, name: "Aprobación Póliza" },
  {
    id: 22,
    name: "Audiencia De Aclaración De Pliego Y Asignación De Riesgos",
  },
  { id: 23, name: "Autorización Numeración De Facturación De La Dian" },
  { id: 24, name: "Aviso De Convocatoria" },
  { id: 25, name: "Aviso Sobre Contratación" },
  { id: 26, name: "Carta De Presentación De Propuesta" },
  { id: 27, name: "Catálogo De Productos" },
  { id: 28, name: "Cedula De Ciudadanía" },
  { id: 29, name: "Certificación Bancaria" },
  { id: 30, name: "Certificación De Fabricante" },
  {
    id: 31,
    name: "Certificación De Información Para Categorización Tributaria",
  },
  { id: 32, name: "Certificación Experiencia Laboral" },
  { id: 33, name: "Certificación Títulos Académicos" },
  { id: 34, name: "Certificado Apertura Cuenta Nomina Davivienda" },
  { id: 35, name: "Certificado Constancia De Empresa" },
  { id: 36, name: "Certificado De Afiliación Eps" },
  { id: 37, name: "Certificado De Afiliación Pensión" },
  { id: 38, name: "Certificado De Antecedentes Disciplinarios Procuraduría" },
  { id: 39, name: "Certificado De Antecedentes Fiscales" },
  { id: 40, name: "Certificado De Antecedentes Judiciales" },
  { id: 41, name: "Certificado De Antecedentes Procuraduría" },
  { id: 42, name: "Certificado De Causal De Inhabilidad O Incapacidad" },
  { id: 43, name: "Certificado De Disponibilidad Presupuestal" },
  { id: 44, name: "Certificado De Existencia Y Representación Legal" },
  { id: 45, name: "Certificado De Garantía" },
  { id: 46, name: "Certificado De La Contraloría General De La Republica" },
  { id: 47, name: "Certificado De La Junta Central De Contadores" },
  {
    id: 48,
    name: "Certificado De Número De Empleados Vinculados A Su Actividad",
  },
  { id: 49, name: "Certificado De Paz Y Salvo" },
  {
    id: 50,
    name: "Certificado Del Sistema Nacional De Medidas Correctivas ",
  },
  { id: 51, name: "Certificado Expedido Por El Rector" },
  { id: 52, name: "Certificado Médico De Ingreso Pre Ocupacional" },
  { id: 53, name: "Certificado No Subasta Electrónica" },
  {
    id: 54,
    name: "Certificado Procedencia De Ingresos Y Calidad De Declarante",
  },
  { id: 55, name: "Certificado Registro Presupuestal" },
  {
    id: 56,
    name: "Certificado Suscrito Por El Revisor Fiscal Del Pago De Parafiscales",
  },
  { id: 57, name: "Certificados Laborales" },
  { id: 58, name: "Comprobante Recaudo Aportes Pila" },
  { id: 59, name: "Concepto De Aptitudes Ocupacionales" },
  { id: 60, name: "Consideraciones" },
  { id: 61, name: "Contrato de Arrendamiento" },
  { id: 62, name: "Contrato de Comisión de Estudio" },
  { id: 63, name: "Contrato de Compraventa" },
  { id: 64, name: "Contrato de Docencia de Cátedra" },
  { id: 65, name: "Contrato de Mantenimiento" },
  {
    id: 66,
    name: "Contrato De Prestación De Servicios Profesionales Y De Apoyo A La Gestión",
  },
  { id: 67, name: "Contrato de Suministro" },
  { id: 68, name: "Contrato Obra Pública " },
  { id: 69, name: "Cronograma Para La Licitación" },
  { id: 70, name: "Declaración De Multas O Incumplimiento" },
  { id: 71, name: "Declaración Juramentada De Bienes Y Renta" },
  { id: 72, name: "Estados Financieros" },
  { id: 73, name: "Estudio Previo" },
  { id: 74, name: "Evaluación De Ofertas" },
  { id: 75, name: "Evaluación Y Aceptación De Oferta" },
  { id: 76, name: "Examen De Disimetría Digital" },
  { id: 77, name: "Experiencias Acreditadas" },
  { id: 78, name: "Factura " },
  { id: 79, name: "Ficha Técnica" },
  { id: 80, name: "Ficha Técnica De La Propuesta Económica" },
  {
    id: 81,
    name: "Formato De Inhabilidades E Incompatibilidades Y De No Obligaciones Alimentarias Vigente",
  },
  { id: 82, name: "Formato Único Hoja De Vida Persona Natural" },
  { id: 83, name: "Formulario Del Registro Único Tributario Rut" },
  { id: 84, name: "Garantías De Seriedad De La Oferta" },
  {
    id: 85,
    name: "Generalidades, Objeto, Apertura Y Cierre De La Licitación",
  },
  { id: 86, name: "Hoja De Ruta" },
  { id: 87, name: "Hoja De Vida De La Función Pública" },
  { id: 88, name: "Informe De Actividades Y Avance Del Contrato" },
  { id: 89, name: "Instructivo De Conexión" },
  { id: 90, name: "Libreta Militar" },
  { id: 91, name: "Manifestación De Ausencia Temporal" },
  { id: 92, name: "Manifestación De Interés " },
  { id: 93, name: "Minuta De Contrato" },
  {
    id: 94,
    name: "Modelo Certificación Contratistas No Tener Más De 1 Empleado",
  },
  { id: 130, name: "Microbiológicos e Informe Fisicoquímicos" },
  { id: 95, name: "Obligaciones Del Contratista" },
  { id: 96, name: "Observaciones" },
  { id: 97, name: "Observaciones A La Evaluación" },
  { id: 98, name: "Observaciones De Los Proponentes" },
  { id: 99, name: "Oferta De Servicios" },
  {
    id: 100,
    name: "Otrosí Modificatorio Al Contrato De Prestación De Servicios Profesionales",
  },
  { id: 101, name: "Planilla Pago Seguridad Social" },
  { id: 102, name: "Pliego De Condiciones" },
  { id: 103, name: "Pliego Definitivo de Condiciones" },
  { id: 104, name: "Poder" },
  { id: 105, name: "Póliza De Seguro" },
  { id: 106, name: "Póliza De Seguro De Cumplimiento Entidades Estatales" },
  {
    id: 107,
    name: "Presentación De Oferta Contrato Prestación De Servicios Profesionales",
  },
  { id: 108, name: "Presentación De Propuesta" },
  { id: 109, name: "Presupuesto Económico Ajustado" },
  { id: 110, name: "Propuesta Económica" },
  {
    id: 111,
    name: "Propuesta Para El Acompañamiento A Los Procesos Pedagógicos, Técnicos Y Conceptuales De La Estrategia De Atención Integral A La Primera Infancia",
  },
  { id: 112, name: "Propuesta Técnica" },
  { id: 113, name: "Prorroga Contrato " },
  { id: 114, name: "Proyecto Pliego De Condiciones" },
  {
    id: 115,
    name: "Registro De Entrega De Refrigerios E Hidratación Entregados",
  },
  { id: 116, name: "Registro Presupuestal" },
  { id: 117, name: "Registro Único De Proponente" },
  { id: 118, name: "Relación De Experiencia" },
  { id: 119, name: "Reporte Del Secop" },
  { id: 120, name: "Resolución Rectoral" },
  { id: 121, name: "Resolución De Adjudicación" },
  { id: 122, name: "Resolución De Apertura" },
  { id: 123, name: "Resolución De Delegación" },
  { id: 124, name: "Respuesta A Observaciones" },
  { id: 125, name: "Sistema Electrónico De Contratación Pública Secop" },
  {
    id: 126,
    name: "Solicitud De Certificado De Disponibilidad Y Registro Presupuestal",
  },
  { id: 127, name: "Tarjeta Profesional" },
  { id: 128, name: "Títulos Académicos" },
  {
    id: 129,
    name: "Verificación De Documentos Proceso De Contratación De Persona Natural",
  }
];
